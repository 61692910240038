import React, { useEffect } from 'react';
import { Card } from '@mui/material';
import Layout from '../layouts';

const styles = {
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  maincard: {
    flex: '1 1 auto',
    padding: 20,
    maxWidth: 600,
    marginBottom: 20
  },
  sidebar: {
    flex: '1 1 auto',
    maxWidth: 360,
    width: '100%',
    verticalAlign: 'top'
  },
  sidecard: {
    maxWidth: 360,
    padding: 20,
    verticalAlign: 'top',
    marginBottom: 20
  },
  img: {
    maxWidth: 320,
    width: '100%',
    display: 'block',
    transition: '0.3s'
  }
};

const Index = ({ data }) => {

  useEffect(() => {
    import('@justinribeiro/lite-youtube');
  }, []);

  return (
    <Layout pageName="">
      <div style={styles.main}>
        <Card style={styles.maincard}>
          <h2>Welcome</h2>
          <p>Are you ready for training, consulting and coaching that actually gets results?</p>
          <p>Is your organization looking for programs that combine the latest performance enhancement approaches
            proven to be effective?</p>
          <p>Do you enjoy learning from a facilitator and certified coach with extensive practical experience,
            excellent listening, communication skills, and a sense of humor?</p>
          <p>Do you need a consultant and a coach who is enthusiastically willing to partner with you and your
            organization to maximize the most important assets: your employees?</p>
          <p>Is your organization ready for the next step?</p>
          <div className="videoWrapper">
            {/* <iframe width="560" height="315" title="Helene Cho Video"
              src="https://www.youtube.com/embed/RP2-5u1OUsY" frameBorder="0"
              allow="autoplay; encrypted-media" allowFullScreen></iframe> */}
            {/* <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/RP2-5u1OUsY"
              srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/RP2-5u1OUsY?autoplay=1><img src=https://img.youtube.com/vi/RP2-5u1OUsY/hqdefault.jpg alt='Helene Cho Leadership Presentation Video'><span>▶</span></a>"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullScreen
              title="Helene Cho Leadership Presentation Video"
            ></iframe> */}
            <lite-youtube
              videoid="RP2-5u1OUsY"
              videotitle="Helene Cho Leadership Presentation Video"
            ></lite-youtube>
          </div>
        </Card>
        <div style={styles.sidebar}>
          <Card style={styles.sidecard}>
            <h3 style={{ margin: '10px 0', color: '#080' }}>Leadership Development</h3>
            <h3 style={{ margin: '10px 0', color: '#080' }}>Facilitation</h3>
            <h3 style={{ marginTop: '10px', color: '#080' }}>Coaching</h3>
            <h3>Certified Facilitator:</h3>
            <ul>
              <li>MBTI (Myers Briggs Temperament Indicator)</li>
              <li>NetSpeed Leadership Solutions</li>
              <li>Master Virtual Trainer</li>
              <li>Gracious Space</li>
            </ul>
            <div>
              <blockquote style={{ color: '#080', maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>"The
                Promised Land always lies on the other side of a wilderness."
                <br /><br /><small><em>- Hemlock Ellis</em></small></blockquote>
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
}

export default Index;
